/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

.font-display {
  font-family: 'Roboto Slab', cursive;
  font-style: italic;
}